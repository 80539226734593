import { z } from "zod";

export const companySchema = z.object({
    name: z.string().max(50, { message: 'validation.invalidMaxLength' }),

    street1: z.string().max(30, { message: 'validation.invalidMaxLength' }),
    street2: z.string().max(30, { message: 'validation.invalidMaxLength' }).nullish().optional(),
    street3: z.string().max(30, { message: 'validation.invalidMaxLength' }).nullish().optional(),
    countryCode: z.string().max(2, { message: 'validation.invalidMaxLength' }),
    zipCode: z.string().max(10, { message: 'validation.invalidMaxLength' }),
    city: z.string().max(30, { message: 'validation.invalidMaxLength' }),

    contactName: z.string().max(50, { message: 'validation.invalidMaxLength' }),
    contactPhone: z.string().max(20, { message: 'validation.invalidMaxLength' }),
    contactEmail: z.string().email({ message: 'validation.invalidEmail' }).nullish(),
    
    financeContactName: z.string().max(50, { message: 'validation.invalidMaxLength' }).nullish().optional(),
    financeContactPhone: z.string().max(20, { message: 'validation.invalidMaxLength' }).nullish().optional(),
    financeContactEmail: z.string().email({ message: 'validation.invalidEmail' }).nullish(),
    
    emailInvoiceTo: z.string().email({ message: 'validation.invalidEmail' }).nullish(),
    orgNo: z.string().max(20, { message: 'validation.invalidMaxLength' }),
    vatNo: z.string().max(20, { message: 'validation.invalidMaxLength' }),

    paymentPlanId: z.string().nullish(),
    nmbExtraUsers: z.number().int().nullish(),
    nmbExtraTrp: z.number().int().nullish(),
    saveExtraDays: z.number().int().nullish(),

    usersCount: z.number().int().nullish(),
    carrierSuitesCount: z.number().int().nullish(),

    createdDt: z.string().nullish(),
    createdBy: z.string().nullish(),
    updatedDt: z.string().nullish(),
    updatedBy: z.string().nullish()
});

export type CompanyType = z.infer<typeof companySchema>;