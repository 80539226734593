import React, { useState, FC } from "react"
import "../../assets/style/Table.css"
import { useTranslate } from "../../hooks"
import { format } from "date-fns"
import { FaCheck, FaCircle, FaMagnifyingGlass, FaTrash } from 'react-icons/fa6';

type dataType = {
  [key:string]: string | number | boolean | Date | object | null | undefined
}

type Props = {
  data: dataType[],
  hideHeaders?: boolean, 
  sortData?: React.Dispatch<React.SetStateAction<any>>,
  openCallback?: (i:number) => void,
  deleteCallback?: (i:number) => void,
  hideColumns?: string[],
  translatePath?: string,
  style?: any,
  [key:string]: any
}

/**
 * 
 * @param data the data rendered. 
 * @param hideHeaders Hides the header
 * @param sortData a function to sort the data
 * @param openCallback a function being called when a row is clicked.
 * @param deleteCallback a function being called when the "trashcan" is clicked. Will not be shown if left undefined.
 * @param hideColumns an array of columns not to show in the table.
 * @returns 
 */

const DataTable:FC<Props> =  ({data, hideHeaders, sortData, openCallback, deleteCallback, hideColumns, style, translatePath, ...props}) => {
    const [ lastSort, setLastSort] = useState<string>()
    const { t } = useTranslate()

    const openable = (typeof openCallback !== 'undefined') 
    const deleteable = (typeof deleteCallback !== 'undefined') 

    if (Array.isArray(data) === false)  return <p>Ingen data</p>
    if (data.length < 1) return <p>Inga rader</p>
    if (data.some((r) => typeof r !== "object")) return <p>felaktig array</p>
    

    let columns = Object.keys(data[0])
    if (deleteable) columns = ['deleteable', ...columns]
    if (openable) columns = ['openable', ...columns]

    if (hideColumns)
      columns = columns.filter(c => !hideColumns.includes(c))

    const sortBy = (h:string) => {
      if (sortData === undefined) return

      let obj = [...data] // Deepcopy not allowed

      obj.sort((a, b) => {
        let val1 = h === lastSort ? b[h] : a[h]
        let val2 = h === lastSort ? a[h] : b[h]
        if ((val1 || '') < (val2 || '')) {
          return -1;
        }
        if ((val1 || '') > (val2 || '')) {
          return 1;
        }
        return 0
      })
      setLastSort(h === lastSort ? undefined : h)
      
      sortData(obj);
    }
    
    return (<div className="schipt-table-body" {...props}>
        <table style={style}>
        {!hideHeaders && 
          <thead>
            <tr key={"r_-1"}>{data[0] &&
              columns.map((h, i) => {
                if (["openable", "deleteable"].includes(h)) return <th key={`r_-1_${i}`}/>
                return <th className="text-nowrap" onClick={() => sortBy(h)} key={`r_-1_${i}`}>{translatePath ? t(`${translatePath}.${h}`) : t(h)}</th>
              })
            }</tr>
          </thead>
        }
        <tbody>{          
          data.map((r, i) => {
            return <tr key={i} onDoubleClick={() => openCallback?.(i)}>{
              r &&
              columns.map((h, j) => {
                const type = typeof r[h]
                let content;
                let clickEvent


                 // Check if date
                if (type === "boolean") content = r[h] ? <FaCheck className='text-schipt-black dark:text-schipt-white' />: "";
                else if (r[h] instanceof Date) content = format(new Date(r[h] as string), "yyyy-MM-dd HH:mm")
                else if (type === "object") content = r[h]
                else content = r[h]?.toString()

                if (h === "openable"){
                  clickEvent = () => openCallback?.(i)
                  content = <FaMagnifyingGlass className='text-schipt-black dark:text-schipt-white' />
                } 
                if (h === "deleteable"){                  
                  clickEvent = () => deleteCallback?.(i)
                  content = <FaTrash className='text-schipt-black dark:text-schipt-white' />
                }              

                return <td 
                  title={typeof clickEvent !== 'undefined' || type == "object" ? undefined: content as string}
                  onClick={clickEvent}
                  className = {(typeof clickEvent !== 'undefined' ? "clickable": undefined) + ' text-nowrap'}
                  key={`r_${i}_${j}`}
                >
                  { (/^http.*\.jpg$/i).test(content as string) && type != "object" ?
                    <img src={content as string} alt='' />
                    :
                    (content as string)
                  }
                </td>
              })
            }</tr>
          })
        }</tbody>
      </table>
    </div>)
    
}

export default DataTable