import React, {FC, useState, useEffect} from 'react'
import { Button, Centralizer } from "../../components"
import "../../assets/style/Stepper.css"

type Props = {
  children: React.ReactElement[],
  labels: string[],
  currentStep?: number,
  callback?: (i:number) => Promise<string>
}

const Stepper: FC<Props> =  ({
  children, 
  labels, 
  currentStep, 
  callback
}) =>  {

  const [ curStep, setCurStep ] = useState(currentStep ?? 0)

  useEffect(() => {
    setCurStep(currentStep ?? 0)
  }, [currentStep])

  if (labels.length !== children.length) throw new Error('Stepper children length must be equal to labels length')

  if (children.length === 0) return <></>

  return <div className='schipt-stepper md:min-w-[900px]'>
    <form onSubmit={async (e) => {      
      e.preventDefault()
      if (typeof callback === "undefined"){
        if (curStep < labels.length - 1) setCurStep((cur) => cur + 1)
      }
      else callback(curStep)
        .then(r => {
          if (curStep < labels.length - 1)
            setCurStep((cur) => cur + 1)
        })        
      }
    }>
      <div className='header flex px-10 items-center w-full text-sm text-schipt-darkgray font-medium'>
        <div className='steps flex items-center text-gray-300'>
          {labels.map((l, i) => {
            return (
              <React.Fragment key={l+i}>
                <div className={'step ' + (curStep === i ? "active" : (i < curStep ? "passed": ""))}>
                  {/*<div className='stepnumber' onClick={() => i < curStep && setCurStep(i)}><p>{i+1}</p></div>*/}
                  <div className='stepnumber' onClick={() => i < curStep && setCurStep(i)}>
                    <span className="w-6 h-6 border bg-schipt-light-gray border-schipt-dark-gray/10 rounded-full flex justify-center items-center mr-3 text-sm text-schipt-black md:w-8 md:h-8">{i+1}</span>
                  </div>
                  <label onClick={() => i < curStep && setCurStep(i)} className="hidden md:block relative top-1.5">{l}</label>
                </div>
                {i < labels.length - 1 && <hr className='step-divider grow m-5 h-px bg-schipt-green' />}
              </React.Fragment>
            )
          })}
        </div>
      </div>

      {children[curStep]}
      <Centralizer>
        <div className='footer'>
          {curStep > 0 &&
            <Button _type='button' className="schipt-btn secondary cancel" onClick={() => setCurStep(curStep-1)}>BACK</Button>
          }
          <Button _type='submit' variant='submit'>{curStep < children.length - 1 ? "NEXT": "FINISH"}</Button>
        </div>
      </Centralizer>
    </form>
  </div>
}

export default Stepper
