import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../hooks';

export default function Logout() {
    const { setAuth } = useAuth()
    
    document.cookie = "jwt=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";

    useEffect(() => {
        if (setAuth) {
            setAuth({} as any);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
        
    return <Navigate to="/" />
}