import React, { useEffect, useState } from 'react'
import { Button, Centralizer, Container, Grid } from '../components'
import { ShipmentType, shipmentSchema } from '../types/Shipments'
import { ZodError, set } from 'zod'
import { goodslineSchema } from '../types/Goodsline'

const ValidateSchema = () => {
    const [data, setData] = React.useState<any>([])
    const [error, setError] = React.useState<any>('')

    const [validationErrors, setValidationErrors] = useState<ZodError<any> | null>(null)
    const [validationPasseed, setValidationPassed] = useState<boolean>(false)
    const [validationMessage, setValidationMessage] = useState<string>('')

    useEffect(() => {
        checkSchema(data)
    }, [data])

    const checkSchema = (data: any) => {
        setValidationErrors(null)
        setValidationMessage('')
        let parsedData = undefined
        try {
            parsedData = JSON.parse(data)
        } catch (e) {
            setError(e.message)
            return false
        }

        let parseResult = undefined
        let parsedType = ''
        if (parsedData.shipment) {
            parsedType = 'shipment'
            parseResult = shipmentSchema.safeParse(parsedData.shipment)
        }
        else if (parsedData.goodsLine) {
            parsedType = 'goodsLine'
            parseResult = goodslineSchema.safeParse(parsedData.goodsLine)
        } else {
            parsedType = 'shipment (no root)'
            parseResult = shipmentSchema.safeParse(parsedData)
        }

        if (!parseResult.success) {
            setValidationPassed(false)
            setValidationMessage(`Schema did not pass validation, validating as ${parsedType}`)
            setValidationErrors(parseResult.error)
            console.log(parseResult.error)
            return false
        } else {
            setValidationPassed(true)
            setValidationMessage(`Schema is valid and parsed as ${parsedType}`)
        }
    }

    const jsonFormat = (data: string) => {
        try {
            return JSON.stringify(JSON.parse(data), null, 2)
        } catch (e) {
            return data
        }
    }

    const handleKeyDown = (e: any) => {
        if (e.key === 'Tab') {
            e.preventDefault();
            const textArea = e.currentTarget; // or use document.querySelector('#my-textarea');
            textArea.setRangeText(
              '\t',
              textArea.selectionStart,
              textArea.selectionEnd,
              'end'
            );
          }
        };

    return (
        <Centralizer style={{
            width: "100vw"
        }}>
            <Container style={{
                height: "40vh",
                maxHeight: "50vh",
                position: "static",
                width: "clamp(1000px, 80%, 80vw)"
            }}>
                <div style={{ display: "flex", flexDirection: "column", justifyContent: "space-between" }}>
                    This is a validator to try and validate the schema of the shipment data.
                    The validation is only of schema not that the data is correct.
                    <br />
                    <label>Paste in the JSON data</label>
                    <textarea
                        style={{
                            minHeight: "40vh",
                            fontFamily: "monospace",
                            border: "1px solid black",
                            padding: "0.2rem",
                            tabSize: 2,
                        }}
                        spellCheck={false}
                        value={data}
                        onChange={(e) => {
                            setError('')
                            let data = e.target.value
                            try {
                                JSON.parse(e.target.value)
                            } catch (e) {
                                setError(e.message)
                            }
                            setData(data)
                        }}
                        onKeyDown={handleKeyDown}
                    />
                    <Grid style={{}}>
                        {/* <Button onClick={() => checkSchema(data)}>Parse</Button> */}
                        <Button onClick={() => setData(jsonFormat(data))}>Format</Button>
                    </Grid>
                    {error && <span style={{ color: "red" }}>JSON is invalid. {error}</span>}
                    {!error && <span style={{ color: "green" }}>JSON is valid.</span>}
                    {validationErrors &&
                        <div style={{ color: "red" }}>
                            {validationErrors.errors.map((error, index) => {
                                return <div key={index}><span style={{ fontWeight: 'bold' }}>{error.path.join('.')}</span> {error.message}</div>
                            })}
                        </div>
                    }
                    {validationMessage && <span style={{ color: "green" }}>{validationMessage}</span>}

                </div>
            </Container>
        </Centralizer>
    )

}

export default ValidateSchema