import React, {useRef, FC, useState, useEffect} from 'react';
import "../../assets/style/Dialog.css"
import { useTranslate } from '../../hooks';
import { TextField } from "../Inputs"

type Props = {
  title?: string,
  message?: string,
  textToWrite: string,
  onOk?: () => void,
  onCancel?: () => void,
}

const WriteToDeleteDialog: FC<Props> = ({textToWrite, title, message, onOk, onCancel}) => {
  const ref = useRef<any>(null)
  const { t } = useTranslate();
  const [ written, setWritten ] = useState("")

  useEffect(() => {
    setWritten('');
  }, [textToWrite])

  if (!textToWrite || textToWrite === "")
    return <></>;    

  return (<>
      <div className='shadowOverlay'>
        <div className='dialogContainer'>
          <h1>{title ?? ''}</h1>
          <div className='dialogMessage'>
              <span style={{whiteSpace: "pre-wrap"}}>{message}</span>
          </div>
          <TextField value={written} onChange={(e) => setWritten(e.currentTarget.value)}/>
          <div className='dialogButtons'>
            <button onClick={onOk} autoFocus={true} ref={ref} className={ ` focus:border-2  delete `}
              disabled={written.toLowerCase() !== textToWrite.toLowerCase()}
              style={{opacity: (written.toLowerCase() !== textToWrite.toLowerCase() ? "40%": "100%")}}
            >{t("delete")}</button>
            <button onClick={onCancel}>{t("cancel")}</button>
          </div>
        </div>
      </div>   
  </>);
}

export default WriteToDeleteDialog