/**
 * Checks if 2 arrays has any common value. Does not work for nested values. 
 * @param arr1 array 1.
 * @param arr2 array 2.
 * @param ignoreNull whether or not to ignore null or undefined values when checking for common values.
 * @returns boolean.
 */
export default function hasCommonValue(arr1:any[], arr2:any[], ignoreNull:boolean = true){
  const commonValues = arr1.filter(value => {
    if(ignoreNull && (value === null || value === undefined)) return false;
    return arr2.includes(value);
  });
  return commonValues.length > 0;
}