import useWebSocket from 'react-use-websocket';
import { useAuth } from './hooks';
import { toast } from "sonner";
import { useCallback, useContext, useEffect, useRef } from 'react';
import { WebsocketContext } from './WebsocketContext';

export const WebsocketApi = () => {
    // const ws = useRef<any>(null)
    const { ws, setWs, setData, data } = useContext(WebsocketContext)
    const { auth } = useAuth()

    useEffect(() => {
        if (!auth?.username || !ws) return
        ws.sendJsonMessage({ type: 'user', username: auth?.username });
    }, [auth, ws])

    // This is a temporary test, will be replaced with a function that gets the websocket url from the server
    const getSocketUrl = useCallback(() => {
        return new Promise<string>((resolve) => {
            setTimeout(() => {
                // resolve('ws://127.0.0.1:8000');
                console.log('WebSocket base url:', process.env.REACT_APP_BASE_WEBSOCKET_URL)    
                resolve(process.env.REACT_APP_BASE_WEBSOCKET_URL || '')
            }, 2000);
        });
    }, []);

    const socket = useWebSocket(getSocketUrl, {
        onOpen: () => {
            // setIsReady(true)
            console.log('WebSocket connection established.');
            if (auth?.username)
                ws && ws.sendJsonMessage({ type: 'user', username: auth?.username });
        },
        onMessage: (event) => {
            // setMessage(event.data)
            try {
                const data = JSON.parse(event.data);
                // console.log('Message received:', data);
                if (data.type === 'UserEvent' && data.message) {
                    if (data.status == 'error') {
                        // console.log('Error:', data.message) 
                        toast.error(data.message)
                    } else if (data.status == 'success') {
                        // console.log('Success:', data.message) 
                        toast.success(data.message)
                    }
                } else if (data.type === 'ConsoleEvent') {
                    console.log(data.message)
                }
                setData(data)
            }
            catch (e) {
                console.error(e)
            }
        },
        onClose: () => {
            // setIsReady(false)
            console.log('WebSocket connection closed.');
        },
        onError: (event) => {
            console.info('WebSocket error:', event);
        },
        retryOnError: true,
        //Will attempt to reconnect on all close events, such as server shutting down
        shouldReconnect: (closeEvent) => true,
    });

    useEffect(() => {
        setData(null)
    }, [data])

    useEffect(() => {
        setWs(socket)
    }, [])

    return null
}