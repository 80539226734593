import React, { FC } from "react"
import "../../assets/style/Container.css"
import { cn } from "../../utils"

type Props = {
    children?: React.ReactElement | React.ReactElement[] | null,
    style?: any,
    className?: string,
    [key: string]: any
}

const Container: FC<Props> =  ({children, style, className, ...props}) => { 
    return(<>
        <div 
            style={style} 
            className={cn("schipt-container", "max-w-full max-h-full p-4", className)} {...props}>
            {children}
        </div>
    </>
    )
}

export default Container