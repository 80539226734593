import React, { FC } from "react"
import "../../assets/style/Centralizer.css"

type Props = {
    children: JSX.Element|JSX.Element[],
    style?: any
}

const Centralizer: FC<Props> =  ({children, style}) => {
    return(<>
        <div style={style} className="schipt-centralizer">
            {children}
        </div>
    </>
    )
}

export default Centralizer