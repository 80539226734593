import React, {FC, useState, useEffect} from 'react'
import "../../assets/style/Button.css"
import { cn } from '../../utils'

type Props = {
  children?: string,
  onClick?:  (e:React.MouseEvent<HTMLButtonElement, MouseEvent>) => void,
  variant?: 'submit' | 'add' | 'delete' | 'undo' | 'save' | 'change' | 'download' | 'default',
  style?: any,
  _type?: "submit" | "button" | "reset" | undefined,
  disabled?: boolean,
  className?: string,
  [key:string]: any
}

const Button: FC<Props> = ({children, onClick, variant, style, _type, disabled, className, ...props}) => {
  if (!variant) variant = "default"
  className = 'schipt-btn ' + (className ?? "")

  if (variant === "add" || variant === "submit") className += ' primary '
  else if (variant === "default") className += ' secondary '
  else if (variant === "delete") className += ' primary cancel '
  else if (variant === "undo") className += ' secondary cancel '

  const [loading, setLoading] = useState(false)
  
  return (
    <button className={cn(`btn`, className)} 
      disabled={disabled || loading}
      type={_type}
      onClick={async(e) => {
        setLoading(true);
        await onClick?.(e)
        setLoading(false);
      }}
      style={style} 
      {...props}
    >
      {children}
    </button>
  )
}

export default Button