import React from 'react'
import Axios from "axios"
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import { useNavigate } from 'react-router'
import '../assets/style/Login.css';
import { Link } from 'react-router-dom';
import { Centralizer, TextField, Container, Button, Group, Grid } from '../components';
import { useTranslate } from '../hooks';
// import BASE_URL from "../utils/Variables/baseURL"
import { ConfirmResetContainer } from '../components';

type locationProps = {
  state: {
    username?: string
  }
}

type messageType = {
  text?: string,
  color?: string
}

const ResetPassword = () => {
  const { token } = useParams();
  const { t } = useTranslate();

  const location = useLocation() as locationProps;

  //Variables for ResetPassword without /Token
  const [username, setUsername] = useState('')
  const [message, setMessage] = useState<messageType>({});
  const [buttonClicked, setButtonClicked] = useState(false)
  const [showButton, setShowButton] = useState(true)

  //Variables with /Token
  const [password, setPassword] = useState('')
  const [repeatedPassword, setRepeatedPassword] = useState('')

  useEffect(() => {
    console.log(location)
    if (location.state?.username)
      setUsername(location.state.username)
  }, [location])

  const navigate = useNavigate()

  useEffect(() => {
    setMessage({})
  }, [username, password, repeatedPassword])

  const sendResetMail = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setMessage({})
    setButtonClicked(true)
    Axios.post(`${process.env.REACT_APP_BASE_URL}/resetPassword`, { username },
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        timeout: 5000
      })
      .then(_ => {
        setShowButton(false)
      })
      .catch((e) => {
        let msg = e?.response?.data?.message ?? e.message
        setMessage({
          text: t(`errorTexts.${msg}`, undefined, msg),
          color: "text-red-600"
        })
        setButtonClicked(false)
      })
  }

  const changePassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setMessage({})
    setButtonClicked(true)

    if (password !== repeatedPassword) {
      setMessage({
        text: t("passwordsNotIdentical"),
        color: "text-red-600"
      })
      setButtonClicked(false)
      return
    }

    Axios.post(`${process.env.REACT_APP_BASE_URL}/changePassword`, {
      resetToken: token,
      password
    },
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        timeout: 5000
      })
      .then(response => {
        console.log("Data:", response)
        setShowButton(false)
        let seconds = 5
        let intervalId = setInterval(() => {
          seconds--
          setMessage({ text: `Password successfully changed!\nYou will be redirected to login page in ${seconds} seconds..` })
          if (seconds === 0) {
            clearInterval(intervalId)
            return navigate("/Login", { state: { username: username } })
          }
        }, 1000)
      })
      .catch((e) => {
        console.error(e)
        let msg = e?.response?.data?.message ?? e.message
        setMessage({
          text: t(`errorTexts.${msg}`, undefined, msg),
          color: "text-red-600"
        })
        setButtonClicked(false)
      })
  }

  return (
    <>{!token ?
      <ConfirmResetContainer username={username} showLoginLink={true}>
        <form onSubmit={(e) => sendResetMail(e)}>
          {showButton ?
            <>
              <TextField autoFocus _type="email" required value={username} onChange={(e) => setUsername(e.target.value)}>{t("email")}</TextField>
              <Button className="schipt-btn primary" disabled={buttonClicked} style={{ marginTop: "2.3rem" }}>{t("resetPassword")}</Button>
            </>
            :
            <h3>Vi har nu skickat ett mail till dig. Följ mailets steg för att byta ditt lösenord!</h3>
          }
        </form>
        {message?.text ?
          <p className={`text-ms mt-2 ${message?.color ?? 'text-neutral-500'} font-bold`} style={{ position: "absolute" }} >{message?.text}</p>
          : <></>
        }
      </ConfirmResetContainer>
      :
      <ConfirmResetContainer username={username} showLoginLink={true}>
        <form onSubmit={(e) => changePassword(e)}>
          <TextField autoFocus _type="password" required value={password} onChange={(e) => setPassword(e.target.value)}>{t("newPassword")}</TextField>
          <TextField _type="password" required value={repeatedPassword} onChange={(e) => setRepeatedPassword(e.target.value)}>{t("repeatPassword")}</TextField>
          <Button disabled={buttonClicked} style={{ marginTop: "2.3rem" }}>{t("resetPassword")}</Button>
          {message?.text ?
            <p className={`text-ms mt-2 ${message?.color ?? 'text-neutral-700'} font-bold whitespace-pre-wrap`}>{message?.text}</p>
            : <></>
          }
        </form>
      </ConfirmResetContainer>
    }</>
  )
}
export default ResetPassword