//import Axios from 'axios';
import React from 'react';
//import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import logo from '../assets/logo.png';
import '../assets/style/Home.css';
import { useAuth } from '../hooks';
import { Link } from 'react-router-dom';
//import schiptToken from "./../scripts.js"
//import { Link } from 'react-router-dom'

export default function Home() {
  const { auth } = useAuth()
  return (
    <>
      <div className="Home">
        <header className="Home-header">
          <img src={logo} alt="logo" />
          Welcome!
          <div>Hello there!</div>
          <h1>HOME PAGE</h1>
          <p>
              User logged in as user {auth?.username}<br />
          </p>
          <Link to="/Shipments">Shipments</Link>
        </header>      
      </div>
    </>
  ); 
}