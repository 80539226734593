import React, { useEffect, useState } from 'react'
import Axios from "axios"
// import BASE_URL from '../utils/Variables/baseURL';
import { Button, Centralizer, ConfirmResetContainer, Container, Password, TextField } from '../components'
import { useTranslate } from '../hooks';
import { useNavigate } from 'react-router'

//"https://online.schipt.com/confirmaccount?vt=A3C8F3A0-A587-49AA-94CB-FF27859B37AE"

const ConfirmAccount = () => {
  const { t } = useTranslate();
  const navigate = useNavigate()

  const [username, setUsername] = useState('') // eslint-disable-line
  const urlParams = new URLSearchParams(window.location.search);
  const verifiedToken = urlParams.get('vt');

  const [message, setMessage] = useState<{ text?: string, color?: string }>({})
  const [password, setPassword] = useState('')
  const [repeatedPassword, setRepeatedPassword] = useState('')
  const [buttonClicked, setButtonClicked] = useState(false)
  const [tokenValid, setTokenValid] = useState(false)
  const [verificationInProgress, setVerificationInProgress] = useState(true)

  const validateToken = () => {
    Axios.get(`${process.env.REACT_APP_BASE_URL}/register/validatetoken?vt=${verifiedToken}`).then(response => {
      console.log(response.data)
      if (response.data)
        setTokenValid(response.data)
      else
        setTokenValid(false)

      setVerificationInProgress(false)
    }).catch(e => {
      console.error(e)
      setTokenValid(false)
      setVerificationInProgress(false)
    })
  }

  useEffect(() => {
    validateToken()
  }, [])

  const handleVerifyAccount = async () => {
    setButtonClicked(true)

    if (password !== repeatedPassword) {
      setMessage({
        text: t("passwordsNotIdentical"),
        color: "text-red-600"
      })
      setButtonClicked(false)
      return
    }

    Axios.post(`${process.env.REACT_APP_BASE_URL}/register/confirm`, {
      vt: verifiedToken,
      password
    },
      {
        headers: { "Content-Type": "application/json" },
        withCredentials: true,
        timeout: 5000
      })
      .then(response => {
        if (response.data.username)
          setUsername(response.data.username)

        let seconds = 5
        let intervalId = setInterval(() => {
          seconds--
          setMessage({ text: `Password successfully set!\nYou will be redirected to login page in ${seconds} seconds..` })
          if (seconds === 0) {
            clearInterval(intervalId)
            return navigate("/Login", { state: { username: response.data.username } })
          }
        }, 1000)
      })
      .catch((e) => {
        console.error(e)
        let msg = e?.response?.data?.message ?? e.message
        setMessage({
          text: t(`errorTexts.${msg}`, undefined, msg),
          color: "text-red-600"
        })
        setButtonClicked(false)
      })
  }

  if (verificationInProgress) {
    return (
      <ConfirmResetContainer showRegisterLink={false} showLoginLink={false}>
        <h1>Verifying registrationtoken...</h1>
      </ConfirmResetContainer>
    )
  } else if ((!verifiedToken) || (!tokenValid)) {
    return (
      <ConfirmResetContainer showRegisterLink={false} showLoginLink={false} showHomeLink={true}>
        <h1>Registrationtoken no longer valid...</h1>
      </ConfirmResetContainer>
    )
  } else {
    return (
      <ConfirmResetContainer username={username} showRegisterLink={false} showLoginLink={false}>
        <h1>{t('confirmAccountHeader')}</h1>

        <TextField autoFocus _type="password" required value={password} onChange={(e) => setPassword(e.target.value)}>{t("newPassword")}</TextField>
        <TextField _type="password" required value={repeatedPassword} onChange={(e) => setRepeatedPassword(e.target.value)}>{t("repeatPassword")}</TextField>

        <Button disabled={buttonClicked} style={{ marginTop: "2.3rem" }} onClick={handleVerifyAccount}>{t("confirmAccountButton")}</Button>
        {message?.text ?
          <p className={`text-ms mt-2 ${message?.color ?? 'text-neutral-700'} font-bold whitespace-pre-wrap`}>{message?.text}</p>
          : <></>
        }
      </ConfirmResetContainer>
    )
  }
}

export default ConfirmAccount