import React, {FC, useState, useEffect} from 'react'
import { useTimeout } from "./../../hooks"
import "../../assets/style/Toast.css"

type Props = {
  options: {
      message: string,
      type: 'info' | 'success' | 'error' | 'warning',
      autoHideDuration?: number,
      onCancel: () => void,
      position?: 'topleft' | 'topcenter' | 'topright' | 
        // 'centerleft' | 'center' | 'centerright' |
      'bottomleft' | 'bottomcenter' | 'bottomright',
    sound?: 'pling' | 'err'
  } | null
}

const Toast: FC<Props> =  ({options}) => {
  const css = {
    error:{
      box: 'bg-red-200 border-2 border-red-800 ',
      logo: ' text-red-800 dark:bg-red-800 dark:text-red-200 ',
      d: 'M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z',
      font: 'font-semibold text-black font-sans',
      viewBox: "0 0 20 20"
    },
    success:{
      box: 'bg-green-100 ',
      logo: ' text-green-500 dark:bg-green-800 dark:text-green-200 ',
      d: 'M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z',
      font: 'font-normal',
      viewBox: "0 0 20 20"
    },
    warning:{
      box: 'bg-amber-100 ',
      logo: ' text-amber-800 dark:bg-yellow-800 dark:text-yellow-200 ',
      d: "M8.257 3.099c.765-1.36 2.722-1.36 3.486 0l5.58 9.92c.75 1.334-.213 2.98-1.742 2.98H4.42c-1.53 0-2.493-1.646-1.743-2.98l5.58-9.92zM11 13a1 1 0 11-2 0 1 1 0 012 0zm-1-8a1 1 0 00-1 1v3a1 1 0 002 0V6a1 1 0 00-1-1z",
      font: 'font-normal',
      viewBox: "0 0 20 20"
    },
    info:{
      box: 'bg-blue-100 ',
      logo: ' text-blue-500 bg-blue-100 dark:bg-blue-800 dark:text-blue-200 ',
      d: "M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16zm.93-9.412-1 4.705c-.07.34.029.533.304.533.194 0 .487-.07.686-.246l-.088.416c-.287.346-.92.598-1.465.598-.703 0-1.002-.422-.808-1.319l.738-3.468c.064-.293.006-.399-.287-.47l-.451-.081.082-.381 2.29-.287zM8 5.5a1 1 0 1 1 0-2 1 1 0 0 1 0 2z",
      font: 'font-normal',
      viewBox: "0 0 20 17"
    },
  }

  const pos = {
    topleft: " absolute left-5 top-5 ",
    topcenter: " absolute top-5 right-0 left-0 my-0 mx-auto ",
    topright: " absolute right-5 top-5 ",
    // centerleft: " left-5 ",
    // center: " absolute left-0 right-0 bottom-0 top-0 m-auto ",
    // centerright: " absolute left-0 right-0 top-0 bottom-0 m-auto ",
    bottomleft: " absolute left-5 bottom-5 ",
    bottomcenter: " absolute bottom-5 right-0 left-0 my-0 mx-auto ",
    bottomright: " absolute right-5 bottom-5 ",
  }

  const [ hide, setHide] = useState(true)
  const [ showLock, toggleShowLock ] = useState(false)  
  
  const { clear, pause, resume, start } = useTimeout(() => {
    setHide(true)
  }, (options?.autoHideDuration ?? 6000))  

  useEffect(() => {
    if(!options) return
    setHide(false)
    start()    
  }, [options])
  
  if (!options) return <></>

  if (!options.position) options.position = "topright"
  if (!options.autoHideDuration || options.autoHideDuration <= 0) options.autoHideDuration = 6000

  const handleClick = () => {
    clear()
    setHide(true)
  }

  return (<>{!hide ?      
    // <div className='w-full h-full absolute '>
      <div className={ css[options.type].box + pos[options.position]  + 'toast'
        // ' flex p-4 mb-4 w-full max-w-lg text-gray-700 transition ease-in.out duration-500 ' +
        // ' rounded-lg shadow dark:text-gray-400 dark:bg-gray-800 cursor-pointer max-h-16 '
        } style={{zIndex:900}} 
        onClick={handleClick} onMouseEnter={() => {
          pause()
          toggleShowLock(true)
        }} onMouseLeave={() => {
          resume(1500, options.autoHideDuration)
          toggleShowLock(false)
        }}        
      >
        <div className={'logo ' + 
           css[options.type].logo}>
          <svg aria-hidden="true" fill="currentColor" viewBox={css[options.type].viewBox} xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" d={css[options.type].d} clipRule="evenodd">
            </path>
          </svg>
        </div>
        <div className={css[options.type].font + ' message'}>{options.message}</div>
        {showLock &&
          <span className='lock'>
            <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-lock-fill" viewBox="0 0 16 16"> <path d="M8 1a2 2 0 0 1 2 2v4H6V3a2 2 0 0 1 2-2zm3 6V3a3 3 0 0 0-6 0v4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h6a2 2 0 0 0 2-2V9a2 2 0 0 0-2-2z"/> 
            </svg>
          </span>
        }        
      </div>
    // </div>      
  : <></> 
  }</>)
}

export default Toast