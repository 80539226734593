import axios from "axios";
// import BASE_URL from "../utils/Variables/baseURL"

export default axios.create({
    baseURL: process.env.REACT_APP_BASE_URL
})

export const axiosPrivate = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL,
    headers: {'Content-Type': 'application/json'},
    withCredentials: true
})