import React from 'react'
import { FaCircleCheck } from 'react-icons/fa6'

type PlanCardType = {
    planId: string,
    title: string,
    description: string,
    price: number,
    currencyCode: string,
    features: string[],
    active?: boolean,
    onClick?: (planId: string) => void,
    interval: string
}

const PlanCard = ({
    planId,
    title,
    description,
    price,
    currencyCode,
    features,
    active,
    onClick,
    interval
}: PlanCardType) => {
    return (
        <>
            {/* <!-- Pricing Card --> */}
            < div className={`p-2 mx-2 md:max-w-xs rounded-xl bg-white dark:bg-schipt-darkest shadow-sm ' ${active ? 'border-2 border-schipt-green': ''} ${active ? 'backgroud-color: red;': ''} `} onClick={() => { onClick && onClick(planId)}} >
                <h3 className="text-sm text-schipt-dark dark:text-schipt-white-60 font-montserrat font-semibold">{title}</h3>
                {description && <p className="font-light text-gray-500 sm:text-lg dark:text-schipt-white-60">{description}</p>}
                <div className="my-2">
                    <span className="mr-2 text-3xl font-extrabold">{price}</span>
                    <span className="text-xs text-gray-500 dark:text-gray-400">{currencyCode}{interval}</span>
                </div>
                {/* <!-- List --> */}                
                <ul className="mb-2 space-y-4 text-left">
                    {features.map((feature, index) => (
                        <li key={index} className="flex items-center space-x-2">
                            {/* <!-- Icon --> */}
                            <FaCircleCheck className='text-schipt-green'/>
                            <span className="text-xxs font-montserrat font-semibold">{feature}</span>
                        </li>
                    ))}
                </ul>
                {/* <a href="#" className="font-medium rounded-lg text-sm px-5 py-2.5 text-center hover:outline">Choose plan</a> */}
            </div >
        </>
    )
}

export default PlanCard