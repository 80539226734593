import React, {ChangeEvent, FC} from 'react';
import "../../assets/style/Switch.css"


interface Props{
    label?: string;
    disabled?: boolean;
    checked?: boolean;
    inputclass?: string;
    labelclass?: string;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => any;
    size?: 'mini'
}

const Switch:FC<Props> = ({label, disabled, checked, inputclass, labelclass, onChange, size}) => {   
    return (
        <div className={`schipt-switch mr-2 mt-4 ${size ?? ''}`}>
            <label className={`inline-flex items-center cursor-pointer${labelclass} `}>
            <input type="checkbox" value="" className={`sr-only peer ${inputclass} `} tabIndex={-1} checked={checked} disabled={disabled} onChange={(e) => onChange?.(e)}/>
            <div className="relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-0 peer-focus:transparent dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600"></div>
            <span className="ms-3 text-sm font-medium text-gray-900 dark:text-gray-300">{label}</span>
            </label>
        </div>
    )
}


/*
const Switch:FC<Props> = ({label, disabled, checked, inputclass, labelclass, onChange, size}) => {   
    return (
        <div className={`schipt-switch mr-2 ${size ?? ''}`}>
            {
                label ?
                <label className={`schipt-switch inline-flex items-center cursor-pointer ${labelclass} `}>{label}</label>:
                <></>
                
            }        
            <input 
                type="checkbox" 
                className={`schipt-switch sr-only peer mr-2 ${inputclass} `}
                tabIndex={-1}
                checked={checked}
                disabled={disabled}
                onChange={(e) => onChange?.(e)}
            />
        </div>
    )
}
*/
export default Switch
