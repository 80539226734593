import React from "react"
import { useLocation, Navigate, Outlet } from "react-router-dom"
import { useAuth } from "../../hooks"
import { jwtDecode } from 'jwt-decode';

const RoleAccess = ({ roles = [] }: {
    roles: string[]
}) => {
    const { auth } = useAuth()
    const location = useLocation()

    const token = auth?.accessToken
    const decodeedToken = jwtDecode(token as string) as any
    const userRole = decodeedToken?.role

    return !roles.length || roles.includes(userRole)
      ? <Outlet />
      : <Navigate to="/unauthorized" replace />;
  };

export default RoleAccess;