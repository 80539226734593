import React, {FC, useEffect, useState} from 'react'
import { Centralizer, Button } from "../../components"

type Props = {
  show: boolean,
  //save: () => void, -- Should be done via FORM and submit
  undo?: () => void
}

const ChangeDialog: FC<Props> = ({show, undo}) => {

  const [ showDialog, setShowDialog] = useState(show);

  useEffect(() => {
    setShowDialog(show)
  }, [show])

  return (<>{showDialog &&
  <div style={{
    position: "fixed",
    bottom: "1.5rem",
    width: "100vw",
    left: 0,
    pointerEvents: 'none',
    zIndex: '10'
  }}>
    <Centralizer>
      <Centralizer>
        <div className="bg-schipt-darkest/40 dark:bg-schipt-darkest/80 pointer-events-auto rounded-md flex justify-between w-full shadow-lg">
          <Button className="schipt-btn secondary" variant='save' _type="submit" style={{
            margin: "1rem 3rem",            
          }}
          //onClick={async () => await save()}
          >Spara ändringar</Button>

          {undo && 
            <Button className="schipt-btn secondary cancel" variant='undo' _type="button" style={{
              margin: "1rem 3rem"            
            }}
            onClick={async () => await undo()}
            >Ångra ändringar</Button>
          }

          
        </div>
      </Centralizer>
    </Centralizer>
  </div>
  }</>)
}

export default ChangeDialog