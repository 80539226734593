import React, { useRef, FC } from 'react';
import "../../assets/style/Dialog.css"
import { useTranslate } from '../../hooks';

export type ConfirmDialogProps = {
    title?: string,
    message?: string,
    noCancel?: boolean,
    type: 'info' | 'success' | 'delete' | 'warning',
    onOk?: () => void,
    onCancel?: () => void,
    okText?: string,
    cancelText?: string
}

/**
 * Displays a custom dialog box with specified options.
 * 
 * @param {string} [options.title] - The title of the dialog box.
 * @param {string} [options.message] - The message to display in the dialog box.
 * @param {boolean} [options.noCancel] - If true, the cancel button will not be shown.
 * @param {'info' | 'success' | 'delete' | 'warning'} options.type - The type of the dialog box. info | success | delete | warning
 * @param {() => void} [options.onOk] - Callback function to be executed when the OK button is clicked.
 * @param {() => void} [options.onCancel] - Callback function to be executed when the Cancel button is clicked.
 * @param {string} [options.okText] - The text to display on the OK button.
 * @param {string} [options.cancelText] - The text to display on the Cancel button.
 */
const ConfirmDialog = ({ 
    options 
}: {
    options: ConfirmDialogProps
}) => {
    const ref = useRef<any>(null)
    const { t } = useTranslate();

    if (!options)
        return <></>;

    return (<>
        <div className='shadowOverlay' onClick={() => {
            ref?.current?.focus && ref.current.focus();
        }}>
            <div className='dialogContainer'>
                <h1>{options?.title ?? ''}</h1>
                <div className='dialogMessage'>
                    <span style={{ whiteSpace: "pre-wrap" }}>{options?.message}</span>
                </div>
                <hr />
                <div className='dialogButtons'>
                    <button onClick={options.onOk} autoFocus={true} ref={ref}
                        className={` focus:border-2  ${options.type} `}
                    >{options.okText ?? t("ok")}</button>
                    {
                        options.noCancel ?
                            <></> :
                            <button onClick={options.onCancel}>{options.cancelText ?? t("cancel")}
                            </button>
                    }
                </div>
            </div>
        </div>
    </>);
}

export default ConfirmDialog