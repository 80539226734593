import React, {FC, useState, useEffect, useId} from 'react'
import "../../assets/style/Select.css"
import { useTranslate } from '../../hooks'
import { TourStepType } from '../../types'
import { Button } from '../Inputs'
import { FaRegLightbulb } from 'react-icons/fa6'
import { sleep } from "../../utils"

type Props = {
  tourSteps: TourStepType[],
  hide?: boolean,
  onEnd?: (finished:boolean) => void
}

type TargetElement = {
  height: number,
  width: number,
  top: number,
  left: number,
}

/**
 * Opens a modal displaying minimal information about the element children.
 * The modal can be clicked "through" until it disapears.
 * @param hide Wether the tour stepper should be shown or not.
 * @param onEnd A callback function when the tour is finished or cancelled.
 * @returns 
 */
const TourStepper: FC<Props> = ({tourSteps, hide, onEnd}) => {
  const [ stepIndex, setStepIndex ] = useState<number>(0)
  const [ currentElementStats, setTargetElementStats ] = useState<TargetElement>()
  const [ currentElement, setCurrentElement ] = useState<HTMLElement | null>()
  const [ orientation, setOrientation ] = useState(tourSteps[stepIndex]?.orientation ?? "bottom")
  const { t } = useTranslate()

  const updateFocusElement = () => {
    if(stepIndex < 0 || stepIndex >= tourSteps.length) return
    if(!currentElement) return

    let width = currentElement.offsetWidth
    let height = currentElement.offsetHeight
    let rect = currentElement.getBoundingClientRect()
    let { top, left } = rect

    setTargetElementStats({width, height, top, left})
  }

  useEffect(() => {
    window.addEventListener("resize",updateFocusElement)    
    return () => window.removeEventListener("resize",updateFocusElement)
  }, [])

  useEffect(() => {
    if(stepIndex < 0 || stepIndex >= tourSteps.length) return
    
    setOrientation(tourSteps?.[stepIndex]?.orientation ?? "bottom")
    
    let targetElement = document.getElementById(tourSteps[stepIndex].id)  

    if(!targetElement){
      console.error(`The tour id ${tourSteps[stepIndex].id} could not be found. tour guide is exited.`)
      setStepIndex(-1)
      return
    }

    setCurrentElement(targetElement)  
  }, [stepIndex])

  useEffect(updateFocusElement, [currentElement])

  if(hide || !currentElementStats) return <></>
  if(stepIndex < 0 || stepIndex >= tourSteps.length) return <></>

  return <div className='absolute w-full h-full top-0 left-0 z-40' //style={{backgroundColor:"hsla(0, 0%, 0%, 25%)"}}
  >
    <div className='absolute' style={{
      ...currentElementStats
      //, border:"2px solid red"
      , boxShadow: "0px 0px 0px max(100vh, 100vw) hsla(0, 0%, 0%, 35%)"
    }}></div>
    <div className='absolute p0 m0 z-50' style={{
      top:  currentElementStats.top + (currentElementStats.height / ((orientation.includes("right") || orientation.includes("left")) ? 10 : 1)),
      left: orientation.includes("left") ? undefined : currentElementStats.left + (orientation.includes("right") ? currentElementStats.width : 10),
      right: orientation.includes("left") ? window.innerWidth - currentElementStats.left: undefined,    
    }}>
      <div className={(orientation.includes("right") || orientation.includes("left")) ? "flex":""}>
        { orientation.includes("left") ?
          <></>
          :
          <div style={{
            width: 0,
            height: 0, 
            borderLeft: (orientation === "bottom" ? "10px solid transparent": undefined),
            borderRight: (orientation === "bottom" ? "10px solid transparent": (orientation === "right" ? "10px solid white": undefined)),
            borderBottom: (orientation === "bottom" ? "10px solid white": (orientation === "right" ? "10px solid transparent": undefined)),
            borderTop: (orientation === "right" ? "10px solid transparent": undefined)
          }}></div>
        }
        
        <div className='p-4' style={{
          backgroundColor: "white",
          maxWidth: "25rem",
        }}      
        >
          <div className="tour-header flex" style={{color:"hsl(0, 0%, 20%)", fontWeight:"700"}}>
            <FaRegLightbulb className='mr-3' style={{color:"blue"}}/>
            <h2>{tourSteps[stepIndex].header}</h2>
          </div>        
          <div className="tour-content" style={{color:"hsl(0, 0%, 20%)"}}>{tourSteps[stepIndex].text}</div>
          <div className='tour-buttons flex w-full' style={{justifyContent:"space-between", marginTop:"1.5rem"}}>
            <span className='mr-6' 
              style={{fontSize:".8em", color:"hsl(0, 0%, 50%)"}}
            >{stepIndex + 1} / {tourSteps.length}</span>
            <Button 
              variant={stepIndex === 0 ? "undo" : "default"} 
              style={{marginLeft:"auto"}} 
              onClick={async() => {
                try{
                  let type = tourSteps[stepIndex].onBack?.constructor.name
  
                  if(type === "AsyncFunction") await tourSteps[stepIndex].onBack?.()
                  else if (type === "Function") tourSteps[stepIndex].onBack?.()

                  if(stepIndex === 0) onEnd?.(false)
                  setStepIndex(cur => cur - 1)
                } catch(e){
                  console.error(`The tour id ${tourSteps[stepIndex].id} got an error: `, e)
                  setStepIndex(-1)
                }                  
              }}
            >{ stepIndex === 0 ? t("skipTour") : t("back")}</Button>       
            <Button onClick={async() => {
              try{
                let i
                let type = tourSteps[stepIndex].onNext?.constructor.name

                if(type === "AsyncFunction") await tourSteps[stepIndex].onNext?.()
                else if (type === "Function") tourSteps[stepIndex].onNext?.()

                if(stepIndex === tourSteps.length - 1) onEnd?.(true)
                setStepIndex(cur => cur + 1)
              } catch(e){
                console.error(`The tour id ${tourSteps[stepIndex].id} got an error: `, e)
                setStepIndex(-1)
              }             
            }}
            >{ stepIndex === tourSteps.length - 1 ? t("finish") : t("next")}</Button>
          </div>
        </div>
        { orientation.includes("left") ?
          <div style={{
            width: 0,
            height: 0, 
            borderLeft: "10px solid white",
            borderRight: (orientation === "bottom" ? "10px solid transparent": (orientation === "right" ? "10px solid white": undefined)),
            borderBottom: "10px solid transparent",
            borderTop: "10px solid transparent"
          }}></div>
          :
          <></>
        }
      </div>
    </div>
  </div>
  
}

export default TourStepper