import React from 'react'
import Priceplans from '../components/Priceplans/Priceplans'
import { Centralizer } from '../components'

const TestPage = () => {
  return (
    <>
      <div>TestPage</div>

      <Centralizer style={{width:"100vw"}}>
        <Priceplans />
      </Centralizer>
    </>
  )
}

export default TestPage