import React, { FC, useEffect, useState } from "react"
import { Button, Select, Switch, TextField } from "../Inputs";
import  { Container, Form } from "../../components"
import { useJSONState } from "../../hooks";

type Props = {
  data: DialogDataType;
  callback: (data: {[key:string]: undefined | boolean | number | string}) => void
  options?: {
    title: string;
  };
}

type DialogDataType = undefined | { 
  [key: string]: {
    type?: "text" | "number" | "select" | "checkbox" | "date" | "email" | "textArea";
    value?: any;
    name?: string;
    readOnly?: boolean;
    hidden?: boolean;
    mandatory?: boolean;
    hoverText?: string;
    placeholder?: string;
    min?: number;
    max?: number;
    step?: number;
    options?: { name: string, value: string | number }[];
  } 
}

/**
 * Opens an input dialog.
 * @param data 
 * @param callback Takes a parameter that contains the data keys and their inputted values as an object.
 * If validation is passed, data must be set to `undefined`.
 * @author Noah Sörman
 */
const Dialog: FC<Props> = ({ data, options, callback }) => {  

  const [ returnData, setProp, setReturnData ] = useJSONState()

  useEffect(() => {
    if(data === undefined) return

    let ret = Object.keys(data).reduce((prev:any, cur:string) => {
      prev[cur] = data[cur].value
      return prev
    }, {})

    setReturnData(ret)
  }, [data])

  if(data === undefined) return <></>
  return <div className="bg-schipt-black/70 w-screen h-screen z-10 fixed"> 
  <div className="absolute w-auto rounded-md bg-schipt-white dark:bg-schipt-dark p-2 shadow-md top-2/4 left-2/4 translate-y-[-90%] translate-x-[-50%]">
    <Container>
      <Form onSubmit={async () => callback(returnData)}>
      <>
      { returnData &&
        Object.keys(data).map(col => {
          let { type, name, placeholder, options } = data[col]

          if (type === "checkbox") return <Switch onChange={(e) => setProp(e.currentTarget.value, col)} checked={returnData[col]}/>
          if (type === "select") return <Select data={options ?? []} onChange={(e) => setProp(e.currentTarget.value, col)} value={returnData[col]} placeholder={placeholder}/>
          if (type === "textArea") return <textarea className="p-0 min-w-72 min-h-24 border-solid border-2 border-schipt-dark-gray/50 m-2 font-openSans text-sm dark:text-black"
          onChange={(e) => setProp(e.currentTarget.value, col)} value={returnData[col]} placeholder={placeholder}/>

          return <div>
            <TextField _type={type ?? "text"} label={name ?? ""} placeholder={placeholder} onChange={(e) => setProp(e.currentTarget.value, col)} value={returnData[col]}/>
          </div>
        })      
      }
      <Button _type="submit" className="flex">Ok</Button>
      </>
      </Form>
    </Container>
  </div>
  </div>
}

export { Dialog, DialogDataType}