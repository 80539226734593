import { z } from "zod";


export const goodslineSchema = z.object({
    lineNo: z.number().optional().nullish(),
    type: z.string().max(20),
    description: z.string().max(40, { message: 'validation.invalidMaxLength' }).optional().nullish(),
    quantity: z.number().min(1),
    weight: z.number(),
    netWeight: z.number().optional().nullish(),
    length: z.number().optional().nullish(),
    width: z.number().optional().nullish(),
    height: z.number().optional().nullish(),
    volume: z.number().optional().nullish(),
    loadmeter: z.number().optional().nullish(),
});

export type GoodslineType = z.infer<typeof goodslineSchema>;