//import Axios from "axios"
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import '../assets/style/Login.css';
import { TextField, Container, Centralizer, Select, Group, Stepper, Toast, Switch } from '../components';
import { useJSONState, useTranslate } from '../hooks';
import { countryList } from '../utils';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { AxiosError } from 'axios';
import Priceplans from '../components/Priceplans/Priceplans';

export default function Register() {
  const api = useAxiosPrivate()
  const { t } = useTranslate()
  const [errMsg, setErrMsg] = useState<any>({})
  const [toastM, setToastM] = useState<any>(null)

  const [registeringDone, setRegisteringDone] = useState(false)

  const [superUser, setSuperUser] = useState<any>({
    email: "",
    fullName: "",
    phoneNo: "",
    termsAgreed: false,
  })

  const [company, setCompanyProp, setCompany] = useJSONState({
    name: "",
    regNo: "",
    vatNo: "",
    emailInvoiceTo: "",
    currencyCode: "",
    paymentTerm: "",
    paymentType: "",

    paymentPlanId: "",
    nmbExtraUsers: 0,
    nmbExtraTrp: 0,
    saveExtraDays: 0,
  })

  const [address, setAddressProp, setAddress] = useJSONState({
    name: "",
    street1: "",
    street2: "",
    countryCode: "SE",
    zipCode: "",
    city: "",
  })

  const checkVat = async (vatNo: string) => {
    if (vatNo.length < 4) return;

    const countryCode = address.countryCode;
    const vatNumber = vatNo.replace(countryCode, '');
    try {
      const result = await api.get({
        endpoint: `/common/check-vat`,
        params: { countryCode, vatNumber },
        mount: null
      });
      const status = (result as any).status
      const data = (result as any).data

      return status === 200 ? data : undefined;
    } catch (e) {
      console.error(e)
      if (e instanceof AxiosError) {
        const status = (e as any).response.status
        const data = (e as any).response.data
        console.log(status, data);
      } else {
        console.error(e)
      }
    }
  }

  const fetchCompanyInfo = async (vatNo: string) => {
    if (vatNo.length < 4) return

    setErrMsg((prev: any) => ({ ...prev, vatNo: undefined }))
    const data = await checkVat(vatNo);
    if (data?.valid) {

      const [street, cityAndZip] = data?.address.split('\n');
      const words = cityAndZip.split(' ');
      const index = words.findIndex((word: string) => /^[a-zA-Z]/.test(word));
      console.log(words, index)

      const zipCode = words.slice(0, index).join(' ');
      const city = words.slice(index).join(' ');

      setCompanyProp(data?.name, "name")
      setAddress({
        name: data?.name,
        street1: street,
        zipCode,
        city,
        countryCode: data?.countryCode,
      })

    } else {
      setErrMsg((prev: any) => ({ ...prev, vatNo: "Invalid VAT number" }))
    }
  }

  const handlePlanChange = (planId: string, extraUsers: number, extraCarrierSuites: number, extraStorageDays: number) => {
    console.log(planId, extraUsers, extraCarrierSuites, extraStorageDays)
    setCompany((prev: any) => ({ 
      ...prev, 
      paymentPlanId: planId,
      nmbExtraTrp: extraCarrierSuites,
      nmbExtraUsers: extraUsers,
      saveExtraDays: extraStorageDays,
    }))
  }

  const handleSubmit = async (i: number): Promise<string> => {
    return new Promise(async (resolve, reject) => {
      setErrMsg({})
      console.log('Stepper', i)
      if (i === 0) {
        console.log("Check orgNo")
        // reject("Invalid orgNo")

      } else if (i === 2) {
        console.log(superUser)
        // console.log("Exec RUS Proc")

        if (!superUser.termsAgreed) {
          setErrMsg((prev: any) => ({ ...prev, finalSubmit: "You must agree to the terms" }))
          resolve("not ok")
          return
        }

        try {
          const response = await api.post({
            endpoint: `/register/company`,
            mount: {
              loadingText: t("registeringCompany"),
              onLoadText: t("companyRegistered")
            },
            data: {
              company: {...company,...address},
              user: {...superUser}  
            }
          })
          console.log(response)
          setRegisteringDone(true)
        } catch (err) {
          console.error(err)
          if (err instanceof AxiosError) {
            const status = (err as any).response.status
            const data = (err as any).response.data
            const message = (err as any).response.data.message
            console.log(status, data, message);
            setErrMsg((prev: any) => ({ ...prev, finalSubmit: message }))
          } else {
            setErrMsg((prev: any) => ({ ...prev, finalSubmit: err?.response?.data?.message ?? err.message }))
          }

          resolve("not ok")
        }
      }
      resolve("ok")
    })
  }

  return <Centralizer>
    <Container
      className="max-h-screen overflow-auto static">
      <Centralizer>
        <Group>
          {!registeringDone ?
            <Stepper
              labels={["Betalplan", "Företagsuppgifter", "Sätt upp Superuser"]}
              currentStep={0}
              callback={handleSubmit}
            >
              <Centralizer style={{ "position": "relative" }}>
                <Priceplans
                  planId={company.paymentPlanId}
                  extraUsers={company.nmbExtraUsers}
                  extraCarrierSuites={company.nmbExtraTrp}
                  extraStorageDays={company.saveExtraDays}
                  onPlanChange={handlePlanChange}
                />
              </Centralizer>
              <Centralizer style={{ position: "relative", alignItems: "start" }}>
                
                {/*<Container>
                  <TextField value={company.vatNo} onChange={e => setCompanyProp(e.target.value, "vatNo")} onBlur={() => { fetchCompanyInfo(company.vatNo) }} required autoFocus error={errMsg?.vatNo}>{t("vatNo")}</TextField>
                  <TextField value={company.name} onChange={e => setCompanyProp(e.target.value, "name")} required>{t("companyName")}</TextField>
                  <TextField _type='email' value={company.emailInvoiceTo} onChange={e => setCompanyProp(e.target.value, "emailInvoiceTo")}>{t("invoiceEmail")}</TextField>
                </Container>
                */}
                {/* </Centralizer>
              <Centralizer> */}
                <Container>
                  <div className="max-w-screen-md mb-8 md:mb-8">
                      <h2 className="mb-4 text-3xl tracking-tight font-extrabold">{t('RegisterCompanyDetails')}</h2>
                      <p className="mb-5 font-light text-gray-500 sm:text-lg">{t('RegisterCompanyDetailsText')}</p>
                  </div>
                
                  <TextField className='mb-2' value={company.vatNo} onChange={e => setCompanyProp(e.target.value, "vatNo")} onBlur={() => { fetchCompanyInfo(company.vatNo) }} required autoFocus error={errMsg?.vatNo}>{t("vatNo")}</TextField>
                  <TextField className='mb-2' _type='email' value={company.emailInvoiceTo} onChange={e => setCompanyProp(e.target.value, "emailInvoiceTo")}>{t("invoiceEmail")}</TextField>
                  <TextField className='mb-2' value={address.name} onChange={e => setAddressProp(e.target.value, "name")} required>{t("companyName")}</TextField>
                  <TextField className='mb-2' value={address.street1} onChange={e => setAddressProp(e.target.value, "street1")} required>{t("street1")}</TextField>
                  <TextField className='mb-2' value={address.street2} onChange={e => setAddressProp(e.target.value, "street2")}>{t("street2")}</TextField>
                  <TextField className='mb-2' value={address.zipCode} onChange={e => setAddressProp(e.target.value, "zipCode")} required>{t("zipCode")}</TextField>
                  <TextField className='mb-2' value={address.city} onChange={e => setAddressProp(e.target.value, "city")} required>{t("city")}</TextField>
                  <Select value={address.countryCode} onChange={e => setAddressProp(e.target.value, "countryCode")} data={countryList} label={t("country")} />
                </Container>
              </Centralizer>
              <Centralizer>
                <Container>
                  <div className="max-w-screen-md mb-8 md:mb-8">
                      <h2 className="mb-4 text-3xl tracking-tight font-extrabold">{t('OrganizationCreated')}</h2>
                      <p className="mb-5 font-light text-gray-500 sm:text-lg">{t('CreateSuperUser')}</p>
                  </div>
                  <TextField value={superUser.email} onChange={(e) => setSuperUser((prev: any) => ({ ...prev, email: e.target.value }))} _type='email' required>{t("email")}</TextField>
                  <TextField value={superUser.fullName} onChange={(e) => setSuperUser((prev: any) => ({ ...prev, fullName: e.target.value }))} required>{t("name")}</TextField>
                  <TextField value={superUser.phoneNo} onChange={(e) => setSuperUser((prev: any) => ({ ...prev, phoneNo: e.target.value }))} required>{t("phone")}</TextField>
                  <a href={t('schipt_useragreement_link')} target='_blank' rel='noreferrer' className="underline text-schipt-green text-sm">{t('schipt_useragreement_linktext')}</a>
                  <Switch checked={superUser.termsAgreed} onChange={(e) => setSuperUser((prev: any) => ({ ...prev, termsAgreed: e.target.checked }))} label={t('approve_agreeement')} />
                  <p className='font-montserrat text-sm'>{t('EndOfRegistrationInfo')}</p>
                  {errMsg?.finalSubmit ? <pre style={{ color: "red" }}>{errMsg.finalSubmit}</pre> : <></>}
                </Container>
              </Centralizer>
            </Stepper>
            :
            <Centralizer>
              <Container>
                <h1>{t('UserAndOrgCreated')}</h1>
                <h2>{t('FollowEmail')} {superUser.email}</h2>
              </Container>
            </Centralizer>
          }
        </Group>
      </Centralizer>
    </Container>
    <Toast options={toastM} />
  </Centralizer>
}