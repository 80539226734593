import React, { createContext, useState, ReactElement, FC } from "react";
import languages from "../assets/languages/index"
import { ZodError } from "zod";

type objectType = {
  [key: string]: string
}


type LanguageContextType = {
  language?: string,
  setLanguage: (languageCode: "sv" | "en") => void,
  tZod: (error: ZodError) => ZodError,
  t: (path: string, args?: objectType | null, fallback?: string) => string,
}

type Props = {
  children: ReactElement
}

const LanguageContext = createContext<LanguageContextType>({} as LanguageContextType);

export const LanguageProvider: FC<Props> = ({ children }) => {
  // get cookies and get language,  

  const [language, setLang] = useState(localStorage.language ?? navigator.language?.substring(0,2) ?? "en");

  const setLanguage = (languageCode: "sv" | "en") => {
    localStorage.language = languageCode
    setLang(languageCode)
  }

  const tZod = (error: ZodError) => {
    error.errors.map((e: any) => {
      const args = {
        max: e.maximum,
        min: e.minimum,
      }
      const fallback = e.message
      if (!e.message.startsWith('validation')) e.message = 'validation.'+e.message
      e.message = t(e.message, args, fallback)
      return e
    })
    console.log(error)
    return error
  }

  const t = (path: string | undefined, args?: objectType | null, fallback?: string):string => {
    try {
      if (!path) return ''
      let langString: string = JSON.stringify(languages[language]);

      if (args)
        Object.keys(args).forEach((p: any) => {
          langString = langString.replace(new RegExp('\\$\\{' + p + '\\}', 'g'), args[p])
        })

      let langObj = JSON.parse(langString)

      path.split('.').forEach((prop) => {langObj = langObj[prop]})
      return langObj.toString() ?? fallback ?? `Missing translation for ${path}`
    } catch (e) {
      return fallback ?? `Missing translation for ${path}`
    }
  }

  return (
    <LanguageContext.Provider value={{ t, tZod, setLanguage, language }}>
      {children}
    </LanguageContext.Provider>
  )
}

export default LanguageContext;
