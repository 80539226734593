import { useState } from "react"

type jsonType = {
  [property: string]: any
}
/**
 * 
 * @param json 
 * @returns 
 * @param param1 - Body
 * @param param2 - SetProperty
 * @param param3 - SetBody
 */
const useJSONState = <T = jsonType>(json?: jsonType): [
  T,
  (value: any, ...properties: (string | number)[]) => void,
  React.Dispatch<React.SetStateAction<T>>
] => {
  
  const [ body, setBody ] = useState<T>(json as T)

  /*
  * Sets a property of the body
  * @param value - value to be set
  * @param properties - path to the property to be set 
  *                     (Can be any number of strings or numbers the specify the path in the json, the last one can be -1 to delete the property)
  */
  function setProp(value: any, ...properties: (string | number)[]){
    let newBody = JSON.parse(JSON.stringify(body))
    let schema = newBody
    const len = properties.length;
    const deleteThis = properties[len - 1] === -1 ? true : false;

    for (let i = 0; i < len-1; i++){
        let elem = properties[i]        
        if (!schema[elem]) schema[elem] = {}
    
        if (deleteThis && i === len - 2){
          if (Array.isArray(schema) && typeof elem === "number") schema.splice(elem, 1)
          else delete schema[elem]
          break;
        }

        schema = schema[elem]
    }
    
    if (!deleteThis) schema[properties[len-1]] = value
    
    setBody(newBody);
  }

  return [body as T, setProp, setBody]
}

export default useJSONState