import React, { FC, useState, useEffect } from 'react'
import schipt_logo from '../../assets/Schipt_black.png';
import schipt_logo_white from '../../assets/Schipt_white.png';
import { Link } from "react-router-dom"
import { Swap } from "../../components"
import "../../assets/style/Navbar.css"
import { useTranslate } from "../../hooks"
import '../../assets/flags/flags.css'
import { useAuth } from '../../hooks'
import { FaBell, FaMoon, FaSun } from 'react-icons/fa6';

type Props = {
    children?: JSX.Element | JSX.Element[]
}

type NavbarItem_Props = {
    children: JSX.Element | JSX.Element[],
    name: string | React.ReactNode,
    open?: boolean,
    id?:string
}

const Navbar: FC<Props> = ({ children }) => {
    const { roleAccess } = useAuth()
    const { t, setLanguage } = useTranslate()
    const [ theme ] = useState<"dark" | "light">(localStorage.theme)


    const NavbarItem: FC<NavbarItem_Props> = ({ children, name, open, id }) => {
        const [showMe, setShowMe] = useState(open)

        return <li className={`schipt-navbar-item ${showMe ? 'active' : ''}`}
            onMouseEnter={() => setShowMe(true)}
            onMouseLeave={() => setShowMe(false)}            
        >
            {name}
            {true &&
                <div id={id}>
                    {children}
                </div>
            }
        </li>
    }

    const closeMobileMenu = (e: any) => {
        document.querySelector('.schipt-navbar input')?.attributes.removeNamedItem('checked')
    }

    return (<>
        <nav className='schipt-navbar relative z-1'>
            <ul className='left'>
                <li>
                    <Link to="/shipments">
                        <img src={theme === 'dark' ? schipt_logo_white : schipt_logo} alt="logo" className='schipt-logo' />
                    </Link>
                </li>
            </ul>
            <ul className="center">
                <li>
                    <Link to="/Shipments" id="navbar-shipments">{t("shipments")}</Link>
                </li>
                <NavbarItem name={t("settings")} id="navbar-settings">
                    <Link to="/Carriers" id="navbar-carriers">{t("carriers")}</Link>
                    <Link to="/DeliveryTypes" id="navbar-deliverytypes">{t("deliveryTypes")}</Link>
                    <Link to="/Addresses" id="navbar-addresses">{t("addresses")}</Link>
                </NavbarItem>
             {/*   
                <NavbarItem name="KPIs">
                    <Link to='#'>Hello</Link>
                    <Link to='#'>Damöb</Link>
                </NavbarItem>
                */}
                <li>
                    <Link to="#">Support</Link>
                </li>
            </ul>
            <ul className='right'>
            <NavbarItem name={<FaBell />}><Link to="#"></Link></NavbarItem>
                <NavbarItem name={t("account.myAccount")}>
                    <Link to="/MyProfile" id="navbar-profile">{t("account.profile")}</Link>
                    {roleAccess && roleAccess(["SuperUser", "Admin"]) ? (
                        <>
                            <Link to="/Subscription" id="navbar-subsriptions">{t("account.subscription")}</Link>
                            <Link to="/Users" id="navbar-users">{t("account.userAdministration")}</Link>
                        </>
                    ) : (
                        <></>
                    )}
                    <Link to='/Logout'>{t("account.logout")}</Link>
                </NavbarItem>
            </ul>
            <div className='mobile'>
                <input type="checkbox" />
                {/* Some spans to act as a hamburger. */}            
                <span></span>
                <span></span>
                <span></span>
                <ul className='menu'>
                    <li className="hover:bg-schipt-dark-gray dark:hover:bg-schipt-darkest hover:bg-opacity-10 dark:hover:bg-opacity-100 dark:text-schipt-white-90">
                        <Link to="/Shipments" onClick={closeMobileMenu}>{t("shipments")}</Link>
                    </li>
                    <li>
                        <p>{t("settings")}</p>                     
                        <ul>
                            <li className="pl-3 hover:bg-schipt-dark-gray dark:hover:schipt-darkest hover:bg-opacity-10 dark:hover:bg-opacity-100 dark:text-schipt-white-90">
                                <Link to="/Carriers" onClick={closeMobileMenu}>{t("carriers")}</Link>
                            </li>
                            <li className="pl-3 hover:bg-schipt-dark-gray dark:hover:schipt-darkest hover:bg-opacity-10 dark:hover:bg-opacity-100 dark:text-schipt-white-90">
                                <Link to="/DeliveryTypes" onClick={closeMobileMenu}>{t("deliveryTypes")}</Link>
                            </li>
                            <li className="pl-3 hover:bg-schipt-dark-gray dark:hover:schipt-darkest hover:bg-opacity-10 dark:hover:bg-opacity-100 dark:text-schipt-white-90">
                                <Link to="/Addresses" onClick={closeMobileMenu}>{t("addresses")}</Link>
                            </li>
                        </ul>
                    </li>
                    {/*
                    <li>
                        KPIs &gt;
                        <ul style={{ marginLeft: '10px' }}>
                            <li className="pl-3 hover:bg-schipt-dark-gray dark:hover:schipt-darkest hover:bg-opacity-10 dark:hover:bg-opacity-100 dark:text-schipt-white-90">
                                <Link to='#' onClick={closeMobileMenu}>Hello</Link>
                            </li>
                        </ul>
                    </li>
                    */}
                    <li>
                        <p>{t("account.myAccount")}</p>
                        <ul>
                            <li className="pl-3 hover:bg-schipt-dark-gray dark:hover:schipt-darkest hover:bg-opacity-10 dark:hover:bg-opacity-100 dark:text-schipt-white-90">
                                <Link to="/MyProfile" onClick={closeMobileMenu}>{t("account.profile")}</Link>
                            </li>
                            <li className="pl-3 hover:bg-schipt-dark-gray dark:hover:schipt-darkest hover:bg-opacity-10 dark:hover:bg-opacity-100 dark:text-schipt-white-90">
                                <Link to="/Subscription" onClick={closeMobileMenu}>{t("account.subscription")}</Link>
                            </li>
                            <li className="pl-3 hover:bg-schipt-dark-gray dark:hover:schipt-darkest hover:bg-opacity-10 dark:hover:bg-opacity-100 dark:text-schipt-white-90">
                                <Link to="/Users" onClick={closeMobileMenu}>{t("account.userAdministration")}</Link>
                            </li>
                        </ul> 
                    </li>

                    <li className="pl-3 hover:bg-schipt-dark-gray dark:hover:schipt-darkest hover:bg-opacity-10 dark:hover:bg-opacity-100 dark:text-schipt-white-90">
                        <Link to="#">Support</Link>
                    </li>
                    <li className="pl-3 hover:bg-schipt-dark-gray dark:hover:schipt-darkest hover:bg-opacity-10 dark:hover:bg-opacity-100 dark:text-schipt-white-90">
                        <Link to="/Logout" onClick={closeMobileMenu}>{t("account.logout")}</Link>
                    </li>
                    {/* <NavbarItem name="Settings">
                        <Link to="#">Carriers</Link>
                        <Link to="#">Delivery Types</Link>
                        <Link to="#">Templates</Link>
                        <Link to="#">Numberseries</Link>
                    </NavbarItem>
                    <NavbarItem name="KPIs">
                        <Link to='#'>Hello</Link>
                        <Link to='#'>Damöb</Link>
                    </NavbarItem>
                    <NavbarItem name="My Account">
                        <Link to="#">Profile</Link>
                        <Link to='/Logout'>Logout</Link>
                    </NavbarItem> */}
                </ul>
            </div>
        </nav>
        {children}
    </>
    )
}

export default Navbar