import React, { FC } from "react"
import { Omit } from "utility-types";
import "../../assets/style/Grid.css"


type Props = {
    children?: JSX.Element|JSX.Element[],
    style?: any,
    [key: string]: any
}

const Grid: FC<Props> =  ({children, style, ...props}) => {
    return(<>
        <div {...props} className={`schipt-grid ${props.className || ''}`} style={style}>
            {children}
        </div>
    </>
    )
}

export default Grid