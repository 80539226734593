import sv from "./sv.json"
import en from "./en.json"

type Language = {
  [key: string]: any
}

const languages: Language = {
  "sv": sv,
  "en": en
}

export default languages