import React, { lazy, useCallback, useContext, useEffect, useState } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import { Toaster, toast } from 'sonner';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
//import Dummy from './pages/Dummy';
import Logout from './components/Logout';
//import HeaderMenu from './pages/HeaderMenu';
//import ProtectedRoute  from "./ProtectedRoute"
import { RequireAuth, PersistLogin, Navbar, RoleAccess } from './components';
import './assets/style/index.css';
import './assets/style/App.css';
import {
    Home, Shipments, Shipment, Carriers, DeliveryTypes, Profile, ValidateSchema, TestPage,
    UserAdministration, Subscription, Register, Login, ResetPassword, ConfirmAccount, AddressRegister
} from "./pages"
import { WebsocketApi } from './WebsocketApi';

const App: React.FC = () => {
    // const ConfirmAccount = React.lazy(() => import('./pages/ConfirmAccount'))
    // On page load or when changing themes, best to add inline in `head` to avoid FOUC

    //darkmode based on browser settings
    useEffect(() => {
        const theme = localStorage.theme

        if(theme === "dark") 
            document.documentElement.classList.add('dark')
        else if(!theme && window.matchMedia('(prefers-color-scheme: dark)').matches)
            document.documentElement.classList.add('dark')
    }, [])

    const Content: React.FC = () => {
        return (
            <Navbar>
                <WebsocketApi />
                <Routes>
                    <Route path="/Home" element={<Home />}></Route>
                    <Route path="/Shipments" element={<Shipments />}></Route>
                    <Route path="/addresses" element={<AddressRegister />}></Route>
                    <Route path="/addresses/:addressId" element={<AddressRegister />}></Route>
                    <Route path="/Shipment/:shipmentId" element={<Shipment />}></Route>
                    <Route path="/Carriers" element={<Carriers />}></Route>
                    <Route path="/Carriers/:carrierId" element={<Carriers />}></Route>
                    <Route path="/DeliveryTypes" element={<DeliveryTypes />}></Route>
                    {/* <Route path="/NumberSeries" element={<NumberSeries />}></Route>
                    <Route path="/NumberSeries/:sqlGuid" element={<NumberSeries />}></Route> */}
                    <Route path="/MyProfile" element={<Profile />}></Route>
                    <Route path="/validateschema" element={<ValidateSchema />}></Route>
                    <Route path="/Test" element={<TestPage />}></Route>
                    <Route path="*" element={<Navigate to="/Shipments" replace />} />
                    <Route element={<RoleAccess roles={["SuperUser", "Admin"]} />}>
                        <Route path="/Users" element={<UserAdministration />}></Route>
                        <Route path="/Subscription" element={<Subscription />}></Route>
                    </Route>
                </Routes>
            </Navbar>
        )
    }


    return (<>
      <Toaster richColors position='top-right' duration={5000} />
      <Routes>
        <Route path="/Logout" element={<Logout />}></Route>
        <Route path="/Register" element={<Register />}></Route>
        <Route path="/ResetPassword/:token" element={<ResetPassword />}></Route>
        <Route path="/ResetPassword" element={<ResetPassword />}></Route>
        <Route path="/ConfirmAccount" element={<ConfirmAccount />}></Route>
        <Route path="/Login" element={<Login />}></Route>
        <Route element={<PersistLogin />}>
          <Route element={<RequireAuth />}>
            <Route path="*" element={<Content />} />
          </Route>
        </Route>
      </Routes>
    </>
    )
}

export default App;