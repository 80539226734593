import React, { createContext, useCallback, useEffect, useRef, useState } from "react"
import useWebSocket from 'react-use-websocket';
import { useAuth } from './hooks';
import { toast } from "sonner";
import { WebSocketHook } from "react-use-websocket/dist/lib/types";


/*
    const { message } = useContext(WebsocketContext);
    useEffect(() => {
        if (message) {
            console.log("Message:", message)      
        }
    }, [message])
*/

export const WebsocketContext = createContext({
  // isReady: false,
  ws: null as WebSocketHook<unknown, MessageEvent<any> | null> | null,
  setWs: (ws: any) => {},
  data: null as any | null,
  setData: (data: any) => {},
  send: (message: any) => {},
  sendJson: (message: any) => {}
})

export const WebsocketProvider = ({
  children
}: {
  children: React.ReactNode
}) => {  
  const [ ws, setWs ] = useState<any>(null)
  const [ data, setData ] = useState<any | null>(null)

  const send = (message: any) => ws?.sendMessage(message)
  const sendJson = (message: any) => ws?.sendJsonMessage(message)

  return (
    <WebsocketContext.Provider value={{ ws, setWs, data, setData, send, sendJson }}>
      {children}
    </WebsocketContext.Provider>
  )
}