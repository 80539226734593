//import { useState } from "react";
import axios from "../api/axios";
import useAuth from "./useAuth";

const useRefreshToken = () => {
    const { setAuth } = useAuth();

    const refresh = async () => {
        const response = await axios.get('/refresh', { withCredentials: true })
        const decodedToken = JSON.parse(atob((response.data.accessToken as string).split('.')[1]))

        if (setAuth) {
            setAuth(prev => {
                return { ...prev, ...{ username: decodedToken.username, accessToken: response.data.accessToken } }
            })
        }
        return response.data.accessToken
    }
    return refresh;
}

export default useRefreshToken