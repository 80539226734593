import React, { useState, useEffect } from 'react'
import { Container, TextField, Centralizer, ChangeDialog, Button, ConfirmDialog, Toast, Accordion, Grid, Group, Select }
  from "../components"
import { deepCopyOf, areIdentical } from "../utils"
import { useLogout, useJSONState, useTranslate } from '../hooks';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import { useNavigate } from 'react-router-dom';

type User = {
  username: string,
  fullName: string,
}

function Profile() {
  const navigate = useNavigate();
  const [user, setUser] = useState<User>(undefined as never as User)
  const [body, setProp, setBody] = useJSONState<User>({})
  const api = useAxiosPrivate()
  const { t, language, setLanguage } = useTranslate()
  const [changed, setChanged] = useState<boolean>(false) //Checks if form has changed
  const [clientKey, setClientKey] = useState("");
  let busyRequesting = false;
  const [confirmDialog, setConfirmDialog] = useState<any>(null);
  const [toastM, setToastM] = useState<any>(null);

  const [ theme, setTheme ] = useState<"dark" | "light">(localStorage.theme)

  useEffect(() => {
    areIdentical(user, body) ? setChanged(false) : setChanged(true)
  }, [body])

  useEffect(() => {
    let isMounted = true;
    const controller = new AbortController();

    const getUserInfo = async () => {
      try {
        const response = await api.get({
          endpoint: `/myProfile`,
          mount: null
        })
        if (isMounted) {
          setClientKey(response.data.clientKey)
          let newBody = deepCopyOf(response.data)
          delete newBody.clientKey
          setBody(newBody);
          setUser(newBody)
        }
      } catch (e) {
        console.error(e)
        //logout()
      }
    }

    getUserInfo()

    return () => {
      isMounted = false;
      controller.abort()
    }
  }, [])

  const newClientKey = async (fromDialog: boolean = false) => {

    if (busyRequesting) return
    try {

      if (!fromDialog) {
        setConfirmDialog({
          title: t("changeClientKey"),
          message: t("clientKeyWhyChange"),
          type: "warning",
          onOk: () => { setConfirmDialog(null); newClientKey(true) },
          onCancel: () => { setConfirmDialog(null) },
          okText: t("updateKey")
        })
        return
      }

      busyRequesting = true
      const response = await api.get({
        endpoint: `/newClientKey`,
        mount: null
      })
      busyRequesting = false
      setClientKey(response.data)
      setToastM({
        message: t("schiptClientRelog"),
        type: "warning",
        oncancel: () => { setToastM(null) }
      })
    } catch (e) {
      busyRequesting = false
      console.error(e)
      //logout()
    }
  }

  const saveUser = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      let requestBody = deepCopyOf(body)
      const response = await api.put({
        endpoint: `/myProfile`,
        data: requestBody,
        mount: {loadingText: t("saving")}
      })
      if (response.status === 200) {
        setUser(body)
        setTimeout(() => setChanged(false), 10)
      }
    } catch (e) {
      console.error(e)
      setConfirmDialog({
        title: t("errorTexts.errorOccured"),
        message: t("errorTexts.errorSaveProfile"),
        type: "warning",
        noCancel: true,
        onOk: () => { setConfirmDialog(null); },
        okText: t("dialogs.close")
      })
    }

    // alert("Saving user")
  }

  return <Centralizer>
    <ConfirmDialog options={confirmDialog} />
    <Toast options={toastM} />
    <Container>
      <Grid>
        <Group label={t('account.info')}>
          <form onSubmit={(e) => saveUser(e)}>
            <Container className='md:w-6/12'>
              <TextField 
                readOnly 
                value={body.username || ''} 
                onChange={(e) => { }}
              >{t("username")}</TextField>
              <TextField 
                required 
                value={body.fullName || ''} 
                onChange={(e) => setProp(e.target.value, "fullName")}
              >{t("fullName")}</TextField>
              <ChangeDialog show={changed}
                undo={async () => {
                setBody(user)
                setTimeout(() => setChanged(false), 10)
              }}
              />
              <Button _type="button" variant="change" className="schipt-btn secondary">New password</Button>
            </Container>
          </form>
        </Group>
        <Group label={t('account.clientService')}>
          <Container className='md:w-6/12'>
              <TextField 
                readOnly 
                disabled={true} 
                value={clientKey || ''}
                _type='password'
              >{t("clientKey")}</TextField>
            <Button _type="button" className="schipt-btn secondary" onClick={() => newClientKey()} >{t("changeClientKey")}</Button>
            <Button _type="button" className="schipt-btn secondary" onClick={() => window.open('https://onlineclient.schipt.com/setup.exe')} >{t("account.download")}</Button>
          </Container>
        </Group>
        <Group label={t('interface')}>
          <Container className='md:w-6/12'>
            <Grid>
              <Select
                data={[
                  {name: t("light"), value:"light"},
                  {name: t("dark"), value:"dark"}
                ]}
                label={t("theme")}
                value={theme}
                onChange={(v) => {
                  let theme = v.currentTarget.value
                  if(theme === "dark")
                    document.documentElement.classList.add('dark')
                  else
                    document.documentElement.classList.remove('dark')
                  localStorage.theme = theme
                  setTheme(theme as ("light" | "dark"))
                }}
              />
              <Select 
                data={[
                  {name: "English", value:"en"},
                  {name: "Svenska", value:"sv"}
                ]}
                label={t("language")}
                value={language}
                onChange={(v) => {
                  console.log(v, v.currentTarget.value)
                  setLanguage(v.currentTarget.value as ("en" | "sv"))
                }}
              />
            </Grid>
          </Container>
        </Group>
        <Button onClick={() => navigate("/Logout")} variant='undo'>{t("account.logout")}</Button>
      </Grid>
    </Container>
  </Centralizer>
}

export default Profile