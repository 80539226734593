import React, { FC } from "react"
import { Container } from "../../components"

import "../../assets/style/Group.css"

type Props = {
    children?: React.ReactElement | React.ReactElement[] | null,
    style?: any,
    containerStyle?: any,
    label?: string | null | undefined,
    className?: string
}

const Group: FC<Props> =  ({children, style, containerStyle, label}) => {
    return(<>
        <fieldset style={style} className="schipt-group">
          {label &&
            <div className="schipt-legend-container">
              <legend>
                {label}
              </legend>
            </div>
          }  
          <div style={containerStyle}>
            {children}
          </div>            
        </fieldset>
    </>
    )
}

export default Group