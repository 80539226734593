import React, {ChangeEvent, FC} from 'react';

interface Props{
    disabled?: boolean;
    onChange?: (e: ChangeEvent<HTMLInputElement>) => any;
    min: number;
    max: number;
    step: number;
    value: number;
    label: string;
}

const Range:FC<Props> = ({disabled, onChange, min, max, step, value,label}) => {   
    return (
      <div className={`schipt-range mr-2 mt-4`}>
        <input id="steps-range" type="range" min={min} max={max} step={step} value={value} disabled={disabled} onChange={(e) => onChange?.(e)} className="w-full h-1 bg-schipt-light-gray rounded-lg appearance-none cursor-pointer dark:bg-schipt-darkest accent-schipt-green "/>
        <p className="font-montserrat mt-2">{label}<output className='font-bold' id='steps-range-value'>{value}</output></p>
      </div>
    )
}

export default Range
