import React, { FC, useMemo, useState } from 'react'
import "../../assets/style/TextField.css"
import { v4 as uuidv4 } from 'uuid';

type Props = {
    children?: string,
    _type?: 'text' | 'email' | 'search' | 'password' | 'date' | 'number' | 'month' | 'week' | 'time' | 'file' | 'tel',
    _ref?: React.MutableRefObject<null>,
    adornment?: string,
    required?: boolean,
    onKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void,
    autoFocus?: boolean,
    defaultValue?: string | number,
    onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void,
    value?: string | number | null,
    readOnly?: boolean,
    onBlur?: (e: React.FocusEvent<HTMLInputElement, Element>) => void,
    title?: string,
    style?: any,
    error?: string,
    className?: string,
    [key: string]: any
}

/**
 * 
 * @param children text to be displayed
 * @param _type text | email | search | password | date | number | month | week | time | file | tel
 * @param {string} _ref a React.MutableRefObject
 * @param {string} adornment unit to be displayed. Examples: kg, km
 * @param {boolean} required 
 * @param {function} onKeyDown 
 * @param {boolean} autoFocus 
 * @param {string} defaultValue deprecated?
 * @param {function} onChange 
 * @param {string} value 
 * @param {string} readOnly 
 * @param {string} onBlur
 * @param {string} title hovertext
 * @returns 
 */
const TextField: FC<Props> = ({ children, _type, _ref, adornment, required, onKeyDown, autoFocus
    , defaultValue, onChange, value, readOnly, onBlur, title, error,className, ...props }) => {
    const [showPassword, setShowPassword] = useState(false);
    const id = useMemo(() => uuidv4(), [])
    return (<>
        <div className='relative w-full md:w-auto'>
            <input type={_type === "password" ? (showPassword ? "text" : "password") : _type ?? "text"}
                id={id}
                placeholder={children}
                ref={_ref}
                required={required ?? false}
                autoFocus={autoFocus ?? false}
                onKeyDown={(e) => onKeyDown?.(e)}
                onChange={(e) => onChange?.(e)}
                defaultValue={defaultValue}
                value={value || ''}
                readOnly={readOnly}
                onBlur={(e) => onBlur?.(e)}
                title={title}
                {...props}
                className={`w-full py-1 pl-1 border-schipt-dark-gray read-only:opacity-50 read-only:pointer-events-none border-solid border text-sm font-montserrat rounded-sm bg-white dark:bg-schipt-dark focus:outline-none placeholder-transparent  focus:shadow-3xl focus:shadow-schipt-dark-gray transition-colors peer ` +
                    (_type === "email" ? "w-80 " : " ")+
                    (className ?? "")
                }
            />
            <label htmlFor={id} className="absolute left-0 -top-4  text-schipt-dark-gray dark:text-schipt-white-90 text-xs font-semibold font-montserrat peer-focus:-top-4 peer-focus:font-md peer-focus:text-md peer-focus:left-0 peer-focus:pl-0 transition-all peer-placeholder-shown:text-schipt-dark-gray dark:peer-placeholder-shown:text-schipt-white-60 peer-placeholder-shown:text-opacity-50 peer-placeholder-shown:pl-1 peer-placeholder-shown:top-2 pointer-events-none">
                {children}
            </label>
            {adornment &&
                <p className='adornment'>{adornment}</p>
            }
            {_type === "password" &&
                <button type="button" className="absolute top-2 ml-1"
                    tabIndex={-1}
                    onClick={() => setShowPassword(!showPassword)}>
                    {showPassword ?
                        <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path d="M13.875 18.825A10.05 10.05 0 0112 19c-4.478 0-8.268-2.943-9.543-7a9.97 9.97 0 011.563-3.029m5.858.908a3 3 0 114.243 4.243M9.878 9.878l4.242 4.242M9.88 9.88l-3.29-3.29m7.532 7.532l3.29 3.29M3 3l3.59 3.59m0 0A9.953 9.953 0 0112 5c4.478 0 8.268 2.943 9.543 7a10.025 10.025 0 01-4.132 5.411m0 0L21 21"></path>
                        </svg>
                        :
                        <svg className="w-5 h-5" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                            <path d="M15 12a3 3 0 11-6 0 3 3 0 016 0z"></path>
                            <path d="M2.458 12C3.732 7.943 7.523 5 12 5c4.478 0 8.268 2.943 9.542 7-1.274 4.057-5.064 7-9.542 7-4.477 0-8.268-2.943-9.542-7z"></path>
                        </svg>
                    }
                </button>
            }
            {error ? <><br /><p className='absolute -top-5 -right-0 text-xs text-rose-500 w-100 text-nowrap'>{error}</p></> : <></>}
        </div>
    </>
    )
}

export default TextField;