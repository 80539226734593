import React from "react"
import { useState, useEffect } from "react"
import { Outlet } from "react-router-dom"
import { useRefreshToken, useAuth } from "../../hooks"

const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true)
    const refresh = useRefreshToken()
    const { auth } = useAuth()

    useEffect(() => {
        const verifyRefreshToken = async () => {
            try{
                await refresh()
            }catch(e){
                console.error(e)
            }
            finally{
                setIsLoading(false);
            }
        }        
        if (!auth?.accessToken) verifyRefreshToken()
        else setIsLoading(false)
        // eslint-disable-next-line
    }, [])    

    return(
        <>
            {isLoading
                ? <p>Loading..</p> // Add spinner here
                : <Outlet />
            }
        </>
    )
}

export default PersistLogin