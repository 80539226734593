import React, { FC, useState, useEffect } from "react"
import "../../assets/style/Accordion.css"
import { FaChevronDown } from "react-icons/fa6"

type Props = {
    children?: JSX.Element|JSX.Element[],
    label: React.ReactNode,
    defaultOpen?: boolean | number,
    _ref?: React.MutableRefObject<null>
}
/**
 * The accordion component allows the user to show and hide sections of related content on a page
 * @param children    a JSX element(s) to be inside the accordion, which can be activeley shown or hidden
 * @param label       the header of the accordion
 * @param defaultOpen accordion is open from the beginning
 * @param _ref        used to reference the accordion. Replaces the usual "ref"-parameter
 * @author Noah Sörman
 */
const Accordion: FC<Props> =  ({children, label, defaultOpen, _ref}) => {

  const boolify = (value:(boolean | number | undefined)) => {
    if(typeof value === "number") return value > 0
    if(typeof value === "boolean") return value
    return false
  }
  const [ open, setOpen ] = useState<boolean>(boolify(defaultOpen))

  useEffect(() => {
    setOpen(boolify(defaultOpen ?? open))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultOpen])

  return(
  <div className={`schipt-accordion ${open ? "open" : ""}`} ref={_ref}>
    <div className="header" onClick={() => setOpen(!open)}>
      <label>{label}</label>
      <i><FaChevronDown className='text-schipt-black dark:text-schipt-white' /></i>
    </div>
    <div className="content">
      {children}
    </div>          
  </div>)
}

export default Accordion