/**
 * 
 * @param a Table One
 * @param b Table Two
 * @param ignorePaths Paths/branches to ignore. Example `["shipment.data.isBooked"]`
 * @returns boolean
 */
export default function areIdentical(a:any, b:any, ignorePaths?:string[]){

  const aif = (a:any, b:any, initial:boolean=true, path?:string) => {
    try{
      if(ignorePaths && path && ignorePaths.indexOf(path) > -1) return true
      if (typeof a !== typeof b) throw new Error("Wrong type")

      if(typeof a === "object"){
        //Array
        if (Array.isArray(a)){
          for (let i = 0; i < Math.max(a.length, b.length); i++){
            let p = path + (path === "" ? "" : ".") + i
            aif(a[i], b[i], false, p)
          }
        }
        //Object
        else{
          Object.keys({...b, ...a}).forEach(ak => {
            let p = path + (path === "" ? "" : ".") + ak
            aif(a[ak], b[ak], false, p)
          })
        } 
      }      
      //Function
      else if (typeof a === "function"){
        if (a.toString() !== b.toString()) throw new Error("Mismatching functions!")        
      }
      //string, number, boolean, undefined, null
      else if (a !== b) throw new Error("Mismatch!")
  
      return true;
    }catch(e){
      if (initial) return false
      else throw e;
    }
  }

  return aif(a, b, true, "")  
}