import React, { useState} from 'react';
import { Centralizer, Container, Grid, Group } from '../Layout'
import { Link } from 'react-router-dom'
import schipt_logo from '../../assets/Schipt_black.png';
import schipt_logo_white from '../../assets/Schipt_white.png';



const ConfirmResetContainer = ({
  children,
  username,
  showHomeLink = false,
  showRegisterLink = true,
  showLoginLink = true,
}: {
  children: React.ReactElement | React.ReactElement[] | null,
  username?: string,
  showHomeLink?: boolean,
  showRegisterLink?: boolean,
  showLoginLink?: boolean,

}) => {
  return (
    <Container style={{ marginTop: "15vh" }}>
      <Centralizer>
        <Container>
          <Group>
            <img className="h-10 mb-3" src={schipt_logo} alt="logo" style={{ margin: "0.3rem" }} />
            <Container style={{ margin: "3rem 2rem" }}>
              {children}
            </Container>
            <Grid style={{ justifyContent: "space-between" }}>
              {showRegisterLink ? <Link to="/Register" className="text-xs ">Create account</Link> : <></>}
              {showHomeLink ? <Link to="/" className="text-xs ">Home</Link> : <></>}
              {showLoginLink ? <Link to="/Login" state={{ 'username': username }} className="text-xs">Login</Link> : <></>}
            </Grid>
          </Group>
        </Container>
      </Centralizer>
    </Container>
  )
}

export default ConfirmResetContainer